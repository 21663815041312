import loadable from '@loadable/component'
import React from "react";
import { createRoot } from 'react-dom/client'

const Results = loadable(() => import('./results'));


if(document.getElementById('deviceResults')){
    const elem = document.getElementById("deviceResults");
    createRoot(elem).render(<Results {...elem.dataset} />)
}