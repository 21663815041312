import loadable from '@loadable/component'
import React from "react";
import { createRoot } from 'react-dom/client'
const AvatarForm = loadable(() => import('./AvatarForm'));

if (document.getElementById("avatar-form")) {
    const component = document.getElementById("avatar-form");

    createRoot(component).render(
        <AvatarForm {...component.dataset}>
        </AvatarForm>
    );
}
