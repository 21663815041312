import loadable from '@loadable/component'
import React from "react";
import { createRoot } from 'react-dom/client'
const VideoPlayer = loadable(() => import('./videoPlayer'));

if (document.getElementById("videoPlayer")) {
    const component = document.getElementById("videoPlayer");

    createRoot(component).render(
        <VideoPlayer {...component.dataset}>
        </VideoPlayer>
    );
}
