import loadable from '@loadable/component'
import React from "react";
import { createRoot } from 'react-dom/client'

const SwitchInput = loadable(() => import('./switchInput'));
export default SwitchInput;

if (document.getElementsByClassName('switchInput')) {
    const list = document.getElementsByClassName("switchInput");
    for (const item of list) {
        createRoot(item).render(<SwitchInput {...item.dataset} />)
    }
}
