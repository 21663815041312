import loadable from '@loadable/component'
import React from "react";
import { createRoot } from 'react-dom/client'

const StarRating = loadable(() => import('./StarRating'));


if (document.getElementsByClassName("starRating")) {
    const list = document.getElementsByClassName("starRating");
    for (const item of list) {
        createRoot(item).render(<StarRating {...item.dataset} />)
    }
}