import loadable from '@loadable/component'
import React from "react";
import { createRoot } from 'react-dom/client'

const Reply = loadable(() => import('./Reply'));


if (document.getElementsByClassName('reply')) {
    const list = document.getElementsByClassName("reply");
    for (const item of list) {
        createRoot(item).render(<Reply {...item.dataset} />)
    }
}
