import loadable from '@loadable/component'
import React from "react";
import { createRoot } from 'react-dom/client'

const Subscribe = loadable(() => import('./Subscribe'));

if (document.getElementsByClassName('subscribe-btn')) {
    const list = document.getElementsByClassName('subscribe-btn');
    for (const item of list) {
        createRoot(item).render(<Subscribe {...item.dataset} />)
    }
}