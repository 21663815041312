import loadable from '@loadable/component'
import React from "react";
import { createRoot } from 'react-dom/client'

const TextEditor = loadable(() => import('./TextEditor'));


if (document.getElementsByClassName('textEditor')) {
    const list = document.getElementsByClassName('textEditor');
    for (const item of list) {
        createRoot(item).render(<TextEditor {...item.dataset} />)
    }
}