import loadable from '@loadable/component'
import React from "react";
import { createRoot } from 'react-dom/client'
const SearchWidget = loadable(() => import('./searchWidget'));

if (document.getElementById("searchwidget")) {
    const component = document.getElementById("searchwidget");

    createRoot(component).render(
        <SearchWidget {...component.dataset}>
        </SearchWidget>
    );
}
