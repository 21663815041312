//Add / Remove Functions
/* How to use following functions:
-----------------------------------
By Class
---------
var els = document.getElementsByClassName('current-class-name');
removeClass(els, 'current-class-name');
addClass(els, 'new-class-name');
    
By ID
---------
var el = document.getElementById('current-class-name');
removeClass([el], 'current-class-name');
addClass([el], 'new-class-name');
*/

const addClass = function (elements, className) {
    for (var i = 0; i < elements.length; i++) {
        var element = elements[i];
        if (element.classList) {
            element.classList.add(className);
        } else {
            element.className += " " + className;
        }
    }
};

const removeClass = function (elements, className) {
    for (var i = 0; i < elements.length; i++) {
        var element = elements[i];
        if (element.classList) {
            element.classList.remove(className);
        } else {
            element.className = element.className.replace(
                new RegExp(
                    "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
                    "gi"
                ),
                " "
            );
        }
    }
};


//End  Add / Remove Functions



// Black overlay for search field
document.querySelectorAll('.overlayBtn, .overlayCloseBtn')
    .forEach(elem => elem.addEventListener('click', function (e) {
        toggleOverlayBlack();
}));
document.addEventListener('keyup', e => {
    if(e.key == 'Escape')
        toggleOverlayBlack();
})

const toggleOverlayBlack = function () {
    document.querySelector('.overlayBlack').classList.toggle('overlayBlack--show');
    document.querySelector('.autocompleteAll input[type="text"]').focus();
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
};



/**
 * Scroll Top Button
 */
const scrollBtn = document.getElementById("scrollBtn");
const navSecondaryBottom = document.getElementById("nav-secondary-bottom");


window.onscroll = function() {scrollFunction()};

function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        scrollBtn.classList.add('d-lg-block');
        navSecondaryBottom.classList.add('show');
    } else {
        scrollBtn.classList.remove('d-lg-block');
        navSecondaryBottom.classList.remove('show');
    }
}

document.querySelectorAll('.scrollToTop')
    .forEach(elem => elem.addEventListener('click', function (e) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
}));


//! app Install => Error ???: caught ReferenceError: checkCookieApp is not defined
// const checkCookieApp = function () {
//     if (getCookieApp("cookiemixiapp") === "") {
//         var d = new Date();
//         d.setTime(d.getTime() + (365*24*60*60*1000));
//         var expires = "expires="+ d.toUTCString();
//         document.cookie = "cookiemixiapp=1; " + expires + "; path=/";
//         document.getElementById("bannerApp").classList.remove('show');
//     }
// }
  
// const getCookieApp = function (name) {
//     var nameEQ = name + "=";
//     var ca = document.cookie.split(';');
//     for(var i=0;i < ca.length;i++) {
//         var c = ca[i];
//         while (c.charAt(0)==' ') c = c.substring(1,c.length);
//         if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
//     }
//     return "";
// }
  
  

// Cookie for inserting google analitycs tags without cookie if user dosn't accept cookies
const getCookie = function (name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

const cookieGAnalitycs = function () {
    var cookietarteaucitron = getCookie('tarteaucitron');
    if (typeof cookietarteaucitron !== 'undefined') {
        let tartesplit = cookietarteaucitron.split('!');
        if (tartesplit && tartesplit.length == 3) {
            if (tartesplit[2] == 'gtag=true')
                return true;
            else if (tartesplit[2] == 'gtag=wait')
                return false;
        }
    }
    return false;
}




// Enable tooltips 
// const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
// const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))



/** Highlight functionnality */
/*!
* In the button or link, add the "click-highlight" class 
* and the target id of the element to highlight with  data-target="{{the_target_id}}"
*/
if (document.getElementsByClassName("click-highlight")) {
    const matches = document.querySelectorAll(".click-highlight");
    matches.forEach((item) => {
        const id = item.dataset.target;
        const targetElem = document.getElementById(id);

        item.addEventListener('click', function() {
            targetElem.classList.remove("nohighlight");
            targetElem.classList.add("highlight");
            setTimeout(() => {
                targetElem.classList.add("nohighlight");
                targetElem.classList.remove("highlight");
            }, "1000");
        });
    });
}

/** Scroll arrow left and right button for responsive display */
if (document.getElementsByClassName("btn-scroll")) {
    document.querySelectorAll('.btn-scroll--left').forEach((item) => {
        item.addEventListener('click', function () { 
            const parent = this.parentElement;
            parent.querySelector('.overflow-auto').scrollBy({ left: -300, behavior: 'smooth' });
            event.preventDefault();
        });
    });
    document.querySelectorAll('.btn-scroll--right').forEach((item) => {
        item.addEventListener('click', function () { 
            const parent = this.parentElement;
            parent.querySelector('.overflow-auto').scrollBy({ left: 300, behavior: 'smooth' });
            event.preventDefault();
        });
    });
}
/** switch up and down the chevron Collapse button */
if (document.getElementsByClassName("readMore")) {
    document.querySelectorAll('.readMore').forEach((item) => {
        item.addEventListener('click', function () { 
            const iconElement = this.querySelector('use');
            const iconLabel = iconElement.getAttribute('href');
            if(iconLabel == '#chevron-down')  iconElement.setAttribute('href', '#chevron-up');
            else iconElement.setAttribute('href', '#chevron-down');
            event.preventDefault();
        });
    });
}

/** */
/*!
* Color mode toggler for Bootstrap's docs (https://getbootstrap.com/)
* Copyright 2011-2023 The Bootstrap Authors
* Licensed under the Creative Commons Attribution 3.0 Unported License.
*/

//** ***** Warning ***** */
//** I have added some code to load the localstorage theme fast in the
//!  partials.head 
//** file to avoid displaying light theme before loading dark */

(() => {
'use strict'

const storedTheme = localStorage.getItem('theme')

const getPreferredTheme = () => {
    if (storedTheme) {
        return storedTheme
    }
    
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
}

const setTheme = function (theme) {
    if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.documentElement.setAttribute('data-bs-theme', 'dark')
    } else {
        document.documentElement.setAttribute('data-bs-theme', theme)
    };
}

setTheme(getPreferredTheme())

const showActiveTheme = (theme, focus = false) => {
    const themeSwitcher = document.querySelector('#bd-theme')

    if (!themeSwitcher) {
    return
    }

    const themeSwitcherText = document.querySelector('#bd-theme-text')
    const activeThemeIcon = document.querySelector('.theme-icon-active use')
    const btnToActive = document.querySelector(`[data-bs-theme-value="${theme}"]`)
    const svgOfActiveBtn = btnToActive.querySelector('svg use').getAttribute('href')

    document.querySelectorAll('[data-bs-theme-value]').forEach(element => {
        element.classList.remove('active')
        element.setAttribute('aria-pressed', 'false')
    })

    btnToActive.classList.add('active')
    btnToActive.setAttribute('aria-pressed', 'true')
    activeThemeIcon.setAttribute('href', svgOfActiveBtn)
    const themeSwitcherLabel = `${themeSwitcherText.textContent} (${btnToActive.dataset.bsThemeValue})`
    themeSwitcher.setAttribute('aria-label', themeSwitcherLabel)

    if (focus) {
        themeSwitcher.focus()
    }
}

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
    if (storedTheme !== 'light' || storedTheme !== 'dark') {
    setTheme(getPreferredTheme())
    }
})

window.addEventListener('DOMContentLoaded', () => {
    showActiveTheme(getPreferredTheme())

    document.querySelectorAll('[data-bs-theme-value]')
    .forEach(toggle => {
        toggle.addEventListener('click', () => {
        const theme = toggle.getAttribute('data-bs-theme-value')
        localStorage.setItem('theme', theme)
        setTheme(theme)
        showActiveTheme(theme, true)
        })
    })
})
})()
