import loadable from '@loadable/component'
import React from "react";
import { createRoot } from 'react-dom/client'

const AutocompleteAll = loadable(() => import('./autocompleteAll'));


if (document.getElementsByClassName('autocompleteAll')) {
    const list = document.getElementsByClassName("autocompleteAll");
    for (const item of list) {
        createRoot(item).render(<AutocompleteAll {...item.dataset} />)
    }
}
