import React, {useState, useEffect} from 'react';
import ReactDOM, { createRoot } from 'react-dom/client'
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles((theme) => ({
    positionAlert: {
      position: 'fixed',
      bottom: '5%',
      right: '80px',
    },
}));

/**
 * Flash
 *
 * @var string body
 * @var string level => success|error|warning|info
 */
const Flash = ({body, level = "success"}) => {
    const [show, setShow] = useState( false );
    const classes = useStyles();
    const flashTime = 7000;

    useEffect(() => {
        hide();
    }, [])

    useEffect(() => {
        if(body !== '') setShow(true)
        return () => {
            setShow(false);
        };
    }, []);


    const hide = () => {
        setTimeout(() => {
            setShow(false);
        }, flashTime);
    }
    
    const getFlashTitle = () => {
        if (level == 'success') return '¡Muy bien! ';
        else if (level == 'error') return '¡Ups! ';
        else if (level == 'info') return 'Info: ';
        else if (level == 'warning') return '¡Ciudado! ';
    }

    return (
        <>
            {show && (
                <Alert className={classes.positionAlert} variant="filled" severity={level}>
                    <b>{getFlashTitle()}</b><span dangerouslySetInnerHTML={{__html: body}} />
                </Alert>
            )}
        </>
    )
    
}

Flash.propTypes = {
    body: PropTypes.string.isRequired,
};

// Set default props
Flash.defaultProps = {
    body: ""
};
export default Flash;

if (document.getElementById("flash")) {
    const elem = document.getElementById("flash");
    createRoot(elem).render(<Flash {...elem.dataset} />)
}
