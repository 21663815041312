import React, { Component } from "react";
import { animateScroll } from "react-scroll";


export const ResultsContext = React.createContext();

export class ResultsProvider extends Component {
    state = {
        products: {},
        searchStatus: "pending",
        orderBy: "price_detail.price_base[12].average_per_month",
        orderByDir: "asc",
        currentPage: 1,
        pageSize: 10,
        resultsRaw: [],
        results: [],
        filters: {},
        providersChecked : 0,
        legends: []
    };

    componentDidMount = () => {
        if (document.getElementById("results")) {
            const resultsProps = {
                ...document.getElementById("results").dataset
            };

            let products = JSON.parse(resultsProps.products);

            this.setState({ products },
                () => this.getData()
            );
        }
    };


    // handleChangeProducts = prodClick => {

    //     let products = { ...this.state.products };

    //     if (prodClick == 'television')
    //         if (products.television === false)
    //             products.fiber = true;

    //     if (prodClick == 'fiber')
    //         if (products.fiber === true)
    //             products.television = false;
    //     products[prodClick] = !products[prodClick];


    //     this.setState({ products },
    //         () => this.getData()
    //     );
    // };

    handleChangeProducts = (prodClick, active) => {

        let products = { ...this.state.products };

        if (prodClick == 'television')
            if (active === true)
                products.fiber = true;

        if (prodClick == 'fiber')
            if (active === false)
                products.television = false;
        
        products[prodClick] = active;

        //Reset some filters data
        // let filters = {...this.state.filters};
        // filters.no_longterm_contract = false;
        // mobile_calls_included
        // mobile_calls_unlimited
        // mobile_data_accumulate
        // mobile_data_included
        // mobile_networks
        // mobile_sms_included
        // mobile_unlimited_call
        // mobile_unlimited_data
        // mobile_unlimited_sms


        this.setState({ products, currentPage: 1, searchStatus: 'pending', providersChecked: 0 },
            () => this.getData()
        );
    };


    handleChangeOrderBy = (orderBy, orderByDir) => {
        this.setState({ orderBy, orderByDir, currentPage: 1 });
    };



    getData = () => {
        //return null;
        let params = '';
        params += (this.state.products.fiber == true) ? '1-' : '0-';
        params += (this.state.products.landline == true) ? '1-' : '0-';
        params += (this.state.products.mobile == true) ? '1-' : '0-';
        params += (this.state.products.television == true) ? '1' : '0';

        let url = `/api/data/` + params + window.location.search;
        let responseLength = 0;

        axios
            .get(url)
            .then(response => {
                //console.log(response);
                if (response.status == 200) {
                    this.handleResponseData(response);
                    //this.setState({ });
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    handleResponseData = response => {
        const {products} = this.state;
        let filters =  {...response.data.filters};
        if( typeof filters.providers != 'undefined' && ( 
                products.mobile == false && (products.fiber == true || products.landline==true)
            ) || (products.mobile == true && products.fiber == false && products.landline == false && products.television == false)
        )
        {
            filters.providers.map((prov, index) => {
                filters.providers[index].isChecked = !prov.isChecked;
            })
        }
        this.setState({
            resultsRaw: response.data.packages,
            results: response.data.packages,
            filters: filters,
            legends: response.data.legends,
            searchStatus: "finished" 
        });
        //this.filterResults();
    };

    // filterResults = () => {
    //     const resultsRawCp = [...this.state.resultsRaw];
    //     const results = resultsRawCp;
    //     this.setState({ resultsRaw: results });
    // };

    handlePageChange = page => {
        this.setState({ currentPage: page }, this.scrollToTop());
    };

    scrollToTop = () => {
        animateScroll.scrollToTop({
            containerId: "results",
            duration: 100,
            delay: 50
        });
    };


    handleFilterChange = (name, val) => {
        const filters = { ...this.state.filters };
        //If it's a slider => object given should have first and last property
        if (name == 'prices' && typeof filters[name][filters.price_period].first != 'undefined' && typeof filters[name][filters.price_period].last != 'undefined') {
            filters[name][filters.price_period].first = val[0];
            filters[name][filters.price_period].last = val[1];
        }
        else if (typeof filters[name].first != 'undefined' && typeof filters[name].last != 'undefined') {
            filters[name].first = val[0];
            filters[name].last = val[1];
        }
        else {
            filters[name] = val;
        }
        this.setState(
            { filters, currentPage: 1 }
            , this.scrollToTop()
        );
        this.filterResults();
    };

    filterResults = () => {
        const resultsRaw = [...this.state.resultsRaw];
    };

    handleProviderCheckedChange = (providersChecked) => {
        this.setState({providersChecked});
    }


    render() {
        return (
            <ResultsContext.Provider
                value={{
                    ...this.state,
                    handleChangeOrderBy: this.handleChangeOrderBy,
                    handlePageChange: this.handlePageChange,
                    handleFilterChange: this.handleFilterChange,
                    handleChangeProducts: this.handleChangeProducts,
                    handleProviderCheckedChange: this.handleProviderCheckedChange
                }}
            >
                {this.props.children}
            </ResultsContext.Provider>
        );
    }
}
