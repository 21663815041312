import loadable from '@loadable/component'
import React from "react";
import { createRoot } from 'react-dom/client'

const DeviceAutocomplete = loadable(() => import('./deviceAutocomplete'));


if (document.getElementsByClassName('deviceAutocomplete')) {
    const list = document.getElementsByClassName("deviceAutocomplete");
    for (const item of list) {
        createRoot(item).render(<DeviceAutocomplete {...item.dataset} />)
    }
}
