import loadable from '@loadable/component'
import React from "react";
import ReactDOM, { createRoot } from 'react-dom/client'
import { ResultsContext, ResultsProvider } from "./context/resultsContext";
//import Results from "./results";
const Results = loadable(() => import('./results'));


if (document.getElementById("results")) {
    const component = document.getElementById("results");
    //const props = Object.assign({}, component.dataset);

    createRoot(component).render(
        <ResultsProvider>
            <ResultsContext.Consumer>
                {({
                    products,
                    orderBy,
                    orderByDir,
                    currentPage,
                    searchStatus,
                    resultsRaw,
                    results,
                    legends,
                    pageSize,
                    filters,
                    handlePageChange,
                    handleChangeProducts,
                    handleChangeOrderBy
                }) => (
                        <Results
                            {...component.dataset}
                            products={products}
                            orderBy={orderBy}
                            orderByDir={orderByDir}
                            currentPage={currentPage}
                            searchStatus={searchStatus}
                            resultsRaw={resultsRaw}
                            results={results}
                            legends={legends}
                            pageSize={pageSize}
                            filters={filters}
                            handlePageChange={handlePageChange}
                            handleChangeProducts={handleChangeProducts}
                            handleChangeOrderBy={handleChangeOrderBy}
                        />
                    )}
            </ResultsContext.Consumer>
        </ResultsProvider>,
        component
    );
}
