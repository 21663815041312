import React, {useState} from 'react';
import { createRoot } from 'react-dom/client'
import Badge from '@mui/material/Badge';
import axios from 'axios';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Button from '@mui/material/Button';

const Favorite = ({id, isfavorited, count, authorize}) => {
    
    const [counter, setCounter] = useState( (count > 0) ? parseInt(count) : 0 );
    const [isFavoritedState, setIsFavoritedState] = useState( isfavorited == '1' ? true : false );

    const handleClick = () => {
        return (isFavoritedState) ? destroy() : create();
    }

    const destroy = () => {
        axios.delete(`/replies/${ id }/favorites`);
        setCounter(counter-1);
        setIsFavoritedState(false);
    }

    const create = () => {
        axios.post(`/replies/${ id }/favorites`);
        setCounter(counter+1);
        setIsFavoritedState(true);
    }

    const defaultProps = {
        color: 'secondary',
        children: <FavoriteIcon />,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }
    };
    


    return (
        <Button onClick={handleClick} disabled={authorize != '1' ? true : false}>
            <Badge badgeContent={counter} {...defaultProps}  />
        </Button>
    );
}
 
export default Favorite;


if (document.getElementsByClassName('favorite')) {
    const list = document.getElementsByClassName('favorite');
    for (const item of list) {
        createRoot(item).render(<Favorite {...item.dataset} />)
    }
}