import loadable from '@loadable/component'
import React from "react";
import { createRoot } from 'react-dom/client'

const Notifications = loadable(() => import('./Notifications'));


if (document.getElementsByClassName('notifications')) {
    const list = document.getElementsByClassName('notifications');
    for (const item of list) {
        createRoot(item).render(<Notifications {...item.dataset} />)
    }
}